import React, { useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Code from "../components/Code";
import { Helmet } from "react-helmet";

const Forgototp = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post('https://your-api-endpoint.com/forgot-password', { emailOrPhone });
      setSuccess('Password reset link has been sent to your email or phone number.');
      setEmailOrPhone(''); // Clear the input field on successful request
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Olive County</title>
        <meta name="title" property="og:title" content="Forgot Password - Olive County" />
        <meta name="description" content="Retrieve your password for your Olive County account. Follow the instructions to securely and conveniently regain access to your account." />
        <meta property="og:description" content="Retrieve your password for your Olive County account. Follow the instructions to securely and conveniently regain access to your account." />
      </Helmet>
      <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);\n        }\n        .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />

      <Navbar />

      <div className="container hero-section">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-12">
            <div className="box" style={{ backgroundColor: 'rgb(222, 243, 209)' }}>    
              <div className="row justify-content-center">
                <div className="col-12">
                  <img src="assets/images/pass.png" alt="" className="w-100" />
                </div>
                <div className="col-12 text-center">
                  <span style={{ fontSize: '20px' }}>Forgot Password?</span>
                </div>
                <div className="row justify-content-center mt-4 mb-5">
                  <div className="col-11">
                    <label htmlFor="emailOrPhone" className="">
                      <b>Email (Phone Number)</b><span className="text-danger"> *</span>
                    </label>
                  </div>
                  <div className="col-11">
                    <div className="row">
                      <div className="col-4 mt-2">
                        <Code />
                      </div>
                      <div className="col-8 mt-2">
                        <input
                          type="text"
                          id="emailOrPhone"
                          className="form-control"
                          placeholder="Enter Phone / Email"
                          value={emailOrPhone}
                          onChange={(e) => setEmailOrPhone(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-11">
                    <button 
                      className="btn btn-primary w-100 mt-4"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Reset Password'}
                    </button>
                  </div>
                  <div className="col-11 text-center mt-4" style={{ fontSize: '17px' }}>
                    Already Registered? <Link to='/login'><span className="text-primary">Back To Login</span></Link>
                  </div>
                  {error && <div className="col-11 text-center mt-4 text-danger">{error}</div>}
                  {success && <div className="col-11 text-center mt-4 text-success">{success}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgototp;
